<template>
  <div class="form-wrapper">
    <form @submit.prevent="$emit('onSubmit')">
      <slot></slot>
    </form>
  </div>
</template>

<script>
export default {
  emits: ["onSubmit"],
};
</script>

<style lang="scss" scoped src="./FormWrapper.scss"></style>
