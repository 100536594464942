<template>
  <div class="centered-wrapper container-wrapper">
    <Logo class="logo" />
    <FormWrapper @onSubmit="onSubmit">
      <h2 class="main-title">Восстановление пароля</h2>
      <hr class="u-mt--1 u-mb--1" />
      <BaseInput
        label="Введите почту"
        v-model="email"
        placeholder="Введите почту"
        type="email"
        class="u-mb--1"
      />
      <Button width="100%" class="u-mb--1" @click="onSubmit"> Сбросить пароль </Button>
      <router-link :to="{ name: 'login' }" class="login-link link u-mt--2 u-mb--1">
        Авторизация
      </router-link>
    </FormWrapper>
  </div>
</template>

<script>
import { ref } from "vue";
// import { useRouter } from "vue-router";
// import api from "@/core/api";

import FormWrapper from "@/core/components/FormWrapper/FormWrapper.vue";
import BaseInput from "@/core/components/BaseInput/BaseInput.vue";
import Button from "@/core/components/Button/Button.vue";

import Logo from "@/assets/icons/logo.svg";

export default {
  components: { Button, FormWrapper, BaseInput, Logo },
  setup() {
    // const router = useRouter();
    const email = ref("");

    const onSubmit = async () => {
      // try {
      //   const data = await api.createUser(userData);
      //   router.push({ name: "login" });
      //   console.log(data);
      // } catch (e) {
      //   console.log(e);
      // }
    };

    return {
      email,
      onSubmit,
    };
  },
};
</script>

<style lang="scss" scoped src="./Restoration.scss"></style>
