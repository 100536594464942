<template>
  <button class="btn" :disabled="disabled" :style="getStyle">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: "40px",
    },
    width: {
      type: String,
      default: "auto",
    },
    color: {
      type: String,
      default: "#fff",
    },
    background: {
      type: String,
      default: "--primary",
    },
    boxShadow: {
      type: String,
      default: "0px 0px 0px 1px #d0d3da",
    },
    borderRadius: {
      type: Number,
      default: 4,
    },
    padding: {
      type: String,
      default: "0 24px",
    },
    fontSize: {
      type: String,
      default: "1rem",
    },
  },
  computed: {
    getStyle() {
      return {
        height: this.height,
        width: this.width,
        minWidth: this.width,
        minHeight: this.height,
        color: this.getColor(this.color),
        background: this.getColor(this.background),
        boxShadow: this.boxShadow,
        borderRadius: `${this.borderRadius}px`,
        padding: this.padding,
        fontSize: this.fontSize,
      };
    },
  },
  methods: {
    getColor(color) {
      const reg = /^-{2}/;
      const matched = reg.test(color);
      return matched ? `var(${color})` : color;
    },
  },
};
</script>

<style lang="scss" scoped src="./Button.scss"></style>
